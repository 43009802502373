form {
  font-size: 16px;
  .form-group {
    display: block;
    margin-bottom: 20px;
    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 16px;
      color: var(--color-base-text);
    }
    input,
    textarea {
      font-size: 16px;
      font-family: var(--font-family-base);
      color: var(--color-base-text);
      -webkit-appearance: none;
      appearance: none;
      border: none;
      display: block;
      width: 100%;
      padding: 10px;
      border-radius: var(--card-border-radius);
      border: 1px solid var(--color-base-bg-3);
      background-color: var(--color-base-bg-3);
    }
  }
  input[type="submit"] {
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
  }
}
