@import 'framework';
@import 'theme';

.declaration-logo-container {
  text-align: center;
}

.declaration-logo {
  width: 60px;
  height: auto;
  margin-bottom: 1.5rem;
}

.declaration-content {
  text-align: justify;
  font-size: 1.25rem;
  padding-top: 1rem;

  h1 {
    font-size: 2.25rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;

    &:first-of-type {
      margin-top: 0;
    }
    
    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  h3 {
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1rem;
  }
}

.signatories {
  h2 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
}
